/* Общие стили */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  color: #333;
  height: 100%;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.toolbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 13px;
}

.btn {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.btn:hover {
  background-color: #555;
}

.setting-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.color-choose {
  padding: 10px 0 10px 8px;
  width: 100px;
  min-height: 70%;
  border-radius: 9px;
  background-color: rgba(94, 25, 51, 0.5);
  border: 2px solid rgba(94, 25, 51, 0);
  display: flex;
  flex-direction: row;
  margin: 10px;
}

input[type="color"] {
  border: 2px solid #4f163a;
  border-radius: 5px;
  width: 102px;
  height: 52px;
  padding: 0;
  cursor: pointer;
  background: transparent;
  box-sizing: content-box;
}

.width-container {
  width: 100px;
  border-radius: 9px;
  background-color: rgba(238, 231, 234, 0.5);
  border: 2px solid rgba(94, 25, 51, 0);
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;
}

.width-choose {
  margin-right: 5px;
  cursor: pointer;
}

.width-choose:nth-child(1) {
  background-color: #000;
  width: 7px;
  height: 7px;
  border-radius: 50px;
}

.width-choose:nth-child(2) {
  background-color: #000;
  width: 10px;
  height: 10px;
  border-radius: 50px;
}

.width-choose:nth-child(3) {
  background-color: #000;
  width: 15px;
  height: 15px;
  border-radius: 50px;
}

.width-choose:nth-child(4) {
  background-color: #000;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.width-choose:nth-child(5) {
  background-color: #000;
  width: 25px;
  height: 25px;
  border-radius: 50px;
}

canvas {
  border-radius: 5px;
  border: 1px solid black;
  min-width: 65%;
}
